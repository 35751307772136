import { TiktokenModel } from "js-tiktoken";
import {
  ModelInputEntry,
  ModelInputPreset,
  ModelInputPresetEntryInput,
  ModelInputPresetEntryInputUserValue,
  Models,
  TaskExecutionResultInput,
  TokenizerModel,
} from "../../../graphql/Codegen/API";
import CoreConstants from "../CoreConstants";
import { countWords, removeAllWhitespace } from "./StringHelper";
let encodingForModel: any;
import("js-tiktoken").then((tiktoken) => {
  encodingForModel = tiktoken.encodingForModel;
});
function convertTokenizerModelToTiktoken(
  tokenizerModel?: TokenizerModel
): TiktokenModel {
  if (tokenizerModel == undefined) return "gpt-4o";
  return tokenizerModel.replace("__", ".").replace("_", "-") as TiktokenModel;
}
export function calculateCostPerUsage(cost: number) {
  return Math.ceil(cost * CoreConstants.CREDIT_TO_USD_RATION * 100) / 100;
}
export function calculateTotalCreditAwardPerUsage(cost: number) {
  return (
    Math.ceil(
      cost *
        CoreConstants.CREDIT_TO_USD_RATION *
        (1 - CoreConstants.PRESET_TAX) *
        100
    ) / 100
  );
}

function presetInputReducedString(
  userInputPresetData: ModelInputPresetEntryInputUserValue
): string | undefined {
  return userInputPresetData.presetEntryDatas?.reduce((sum, current) => {
    return sum + current?.val ?? "";
  }, "");
}

export function countTokens(
  inputText: string,
  tokenizerModel?: TokenizerModel
) {
  let convertedModel = convertTokenizerModelToTiktoken(
    tokenizerModel ?? TokenizerModel.gpt_4o
  );
  const enc = encodingForModel(convertedModel);
  const tokens = enc.encode(inputText);
  return tokens.length;
}
function calculateTokenCosts(
  targetInput: ModelInputEntry,
  userInputData: TaskExecutionResultInput,
  activePresetData: ModelInputPresetEntryInput | undefined | null,
  userInputPresetData: ModelInputPresetEntryInputUserValue | undefined | null
) {
  if (
    targetInput.cost?.costPerToken == undefined ||
    targetInput.cost?.tokenizerModel == undefined
  )
    return 0;

  let costBasis = 0;
  let convertedModel = convertTokenizerModelToTiktoken(
    targetInput.cost.tokenizerModel
  );
  const enc = encodingForModel(convertedModel);
  const tokens = enc.encode(userInputData.value);
  costBasis += Math.max(
    1,
    Math.round(tokens.length * targetInput?.cost.costPerToken)
  );

  //Preset Defaults
  if (activePresetData) {
    let presetTokensCount =
      activePresetData?.presetEntryDatas?.reduce((sum, current) => {
        return sum + (current?.metaData?.TOKENS_COUNT ?? 0);
      }, 0) ?? 0;
    costBasis += Math.round(
      presetTokensCount * targetInput.cost.costPerToken ?? 0
    );
  }
  //Preset User Input
  if (userInputPresetData) {
    let accumulatedUserPresetInput =
      presetInputReducedString(userInputPresetData);
    const presetTokens = enc.encode(accumulatedUserPresetInput);
    costBasis += Math.round(
      presetTokens.length * targetInput?.cost.costPerToken
    );
  }
  return costBasis;
}
function calculateStringCosts(
  targetInput: ModelInputEntry,
  userInputData: TaskExecutionResultInput,
  activePresetData: ModelInputPresetEntryInput | undefined | null,
  userInputPresetData: ModelInputPresetEntryInputUserValue | undefined | null
) {
  let costBasis = 0;
  if (targetInput?.cost?.costPerWord != undefined) {
    if (userInputData.value) {
      costBasis +=
        countWords(userInputData.value) * targetInput.cost.costPerWord;
    }
    //Preset Defaults
    if (activePresetData?.presetEntryDatas) {
      activePresetData.presetEntryDatas.forEach((entry) => {
        if (entry?.val)
          costBasis +=
            countWords(entry.val) * (targetInput?.cost?.costPerWord ?? 0);
      });
    }

    //Preset User Input
    if (userInputPresetData) {
      let accumulatedUserPresetInput =
        presetInputReducedString(userInputPresetData);
      if (accumulatedUserPresetInput) {
        costBasis +=
          countWords(accumulatedUserPresetInput) *
          targetInput?.cost.costPerWord;
      }
    }
  }

  if (targetInput?.cost?.costPerCharacter) {
    if (userInputData.value) {
      costBasis +=
        removeAllWhitespace(userInputData.value).length *
        targetInput?.cost.costPerCharacter;
    }

    //Preset Defaults
    if (activePresetData?.presetEntryDatas) {
      let presetCharacterLength = activePresetData.presetEntryDatas.reduce(
        (sum, current) => {
          return sum + (current?.metaData?.STRING_LENGTH ?? 0);
        },
        0
      );
      costBasis += presetCharacterLength * targetInput?.cost.costPerCharacter;
    }

    //Preset User Input
    if (userInputPresetData) {
      let accumulatedUserPresetInput =
        presetInputReducedString(userInputPresetData);
      if (accumulatedUserPresetInput) {
        costBasis +=
          removeAllWhitespace(accumulatedUserPresetInput).length *
          targetInput?.cost.costPerCharacter;
      }
    }
  }
  return costBasis;
}
function calculateInputEntryCost(
  modelInfo: Models,
  userInputData: TaskExecutionResultInput,
  activePresetData: ModelInputPresetEntryInput | undefined | null,
  userInputPresetData: ModelInputPresetEntryInputUserValue | undefined | null
): { costBasis: number; costMultiplier: number } {
  let targetInput = modelInfo.inputs?.entries?.find(
    (modelInput) => modelInput?.name == userInputData.name
  );
  var costBasis = 0;
  var costMultiplier = 1;
  if (targetInput == undefined) return { costBasis, costMultiplier };

  if (targetInput?.cost?.flatFee) {
    costBasis += targetInput.cost.flatFee;
  }
  costBasis += calculateTokenCosts(
    targetInput,
    userInputData,
    activePresetData,
    userInputPresetData
  );

  if (targetInput?.cost?.isMultiplier == true) {
    if (userInputData.value) {
      if (+userInputData.value > 0) costMultiplier *= +userInputData.value;
    }
    //Preset Defaults
    if (activePresetData) {
      costMultiplier *= +(activePresetData?.presetEntryDatas?.[0]?.val ?? 1);
    }
  }
  costBasis += calculateStringCosts(
    targetInput,
    userInputData,
    activePresetData,
    userInputPresetData
  );
  if (targetInput?.cost?.costPerValue != undefined) {
    if (userInputData.value)
      costBasis += +userInputData.value * targetInput?.cost?.costPerValue;
    //Preset Defaults
    if (activePresetData) {
      activePresetData.presetEntryDatas?.forEach((entry) => {
        if (entry?.val)
          if (userInputData.value)
            costBasis +=
              +userInputData.value * (targetInput?.cost?.costPerValue ?? 0);
      });
    }
  }
  if (targetInput?.cost?.costPerFileDuration) {
    const duration = userInputData?.metaData?.DURATION;
    if (duration) {
      const audioMinutes = +duration / 60;
      if (targetInput?.cost?.costPerFileDuration)
        costBasis += Math.round(
          audioMinutes * targetInput?.cost?.costPerFileDuration
        );
    }
    //Preset Defaults
    let presetEntryDatas = activePresetData?.presetEntryDatas;
    if (presetEntryDatas != undefined) {
      let allDurations =
        presetEntryDatas.reduce((sum, current) => {
          return sum + (current?.metaData?.DURATION ?? 0);
        }, 0) / 60.0;
      costBasis += Math.round(
        allDurations * targetInput?.cost.costPerFileDuration
      );
    }
  }
  targetInput?.options?.forEach((option) => {
    if (option?.cost) {
      if (userInputData.value == option.name) costBasis += option.cost;
      //Preset Defaults
      if (activePresetData?.presetEntryDatas) {
        activePresetData!.presetEntryDatas!.forEach((entry) => {
          if (entry?.val) {
            if (entry.val == option) costBasis += option.cost ?? 0;
          }
        });
      }
    }
  });

  // costInterpolateMin
  // costInterpolateMax
  // costPerFileSize
  return { costBasis, costMultiplier };
}

export function calculateQuote(
  modelInfo: Models,
  userInputs: TaskExecutionResultInput[],
  activePreset: ModelInputPreset | undefined,
  presetInputUserValues: ModelInputPresetEntryInputUserValue[] | undefined
): number {
  var finalCost = 0;
  var finalMultiplier = 1;
  for (let inputData of userInputs) {
    let { costBasis, costMultiplier } = calculateInputEntryCost(
      modelInfo,
      inputData,
      activePreset?.entries?.find((x) => x?.modelInputName == inputData.name),
      presetInputUserValues?.find((x) => x?.modelInputName == inputData.name)
    );
    finalCost += costBasis;
    finalMultiplier *= costMultiplier;
  }
  return Math.ceil(finalCost * finalMultiplier);
}
